<template>
	<div class="salesOverviewbox">
		<div class="filter-container" style="padding-top: 15px;">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" style="margin-left:5px;width: 300px;" placeholder="商品名称、商品编码" clearable @change="wordChange"></el-input>
			</div>
			<div>
				<div class="filter-item">
					<label class="label">时间:</label>
					<el-date-picker type="date" placeholder="选择日期" v-model="starTime" value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
					~
					<el-date-picker type="date" placeholder="选择日期" v-model="endTime" value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
					<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
				</div>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading="loading" @sort-change="sortChange">
				<el-table-column key="1" label="商品" width="400px">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img v-if="scope.row.ImgUrl" :src="imgUrl + scope.row.ImgUrl + '@!cut64'" style="width: 64px;height: 64px;" />
							<svg-icon v-else icon-class="noImgIcon" />
							<div style="margin-left: 10px;margin-right: 100px;height: 64px;">
								<div class="twoFlows">{{ scope.row.Name }}</div>
								<div style="color: #666;">{{ scope.row.ProductNo }}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column key="2" prop="SellMoney" label="销售额" sortable></el-table-column>
				<el-table-column key="3" label="分享次数" prop="ShareCount" sortable></el-table-column>
				<el-table-column key="4" label="分享访问次数" sortable prop="SharePvCount"></el-table-column>
				<el-table-column key="5" label="素材下载次数" sortable prop="MaterialDownloadCount"></el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination
					v-if="Total"
					style="margin-top:20px;float:right;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="Total"
				></el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import config from '@/config/index';
import { dataStatisProductPromotionIndex, dataStatisProductPromotionExport } from '@/api/TurnTomySelf.js';
export default {
	data() {
		return {
			searchKey: '',
			starTime: '',
			endTime: '',
			grade: null,
			loading: false,
			exportUrl: config.EXPORT_URL,
			currentPage: 1,
			pageSize: 20,
			Total: 0,
			OrderBy: '',
			IsAsc: 0,
			imgUrl: config.IMG_BASE,
			tableData: []
		};
	},
	beforeMount() {
		this.getNowDay();
		this.getList();
	},
	created() {},
	methods: {
		showlockfanList() {
			//跳转明细退款
		},
		showRecommendList(row) {
			//跳转明细
		},
		sortChange(column, prop, order) {
			if (column.order == 'ascending') {
				this.IsAsc = true;
				this.OrderBy = column.prop;
			} else if (column.order == 'descending') {
				this.IsAsc = false;
				this.OrderBy = column.prop;
			} else {
				this.IsAsc = false;
				this.OrderBy = '';
			}
			this.currentPage = 1;
			this.getList();
		},
		async getList() {
			try {
				this.loading = true;
				let data = {
					KeyWords: this.searchKey,
					StartTime: this.starTime,
					EndTime: this.endTime,
					Skip: (this.currentPage - 1) * this.pageSize,
					Take: this.pageSize,
					OrderBy:  this.OrderBy,
					IsAsc: this.IsAsc
				};
				let result = await dataStatisProductPromotionIndex(data);
				this.tableData = result.Result.Results;
				this.Total = result.Result.Total;
			} catch (e) {
				//TODO handle the exception
			} finally {
				this.loading = false;
			}
		},
		// 导出
		async exportFun() {
			try {
				this.loading = true;
				if (!this.starTime || !this.endTime || this.getDaysBetween(this.starTime, this.endTime) > 31) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多可查询31天内的订单，请重新选择时间!'
					});
					return;
				}
				let url =
					this.exportUrl +
					'/pc/dataStatisProductPromotion/export?' +
					'&KeyWords=' +
					(this.searchKey ? encodeURIComponent(this.searchKey) : '') +
					'&StartTime=' +
					(this.starTime ? this.starTime : '') +
					'&EndTime=' +
					(this.endTime ? this.endTime : '');
				console.log(url, '导出的地址');
				window.open(url);
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},
		handleFilter() {
			if (!this.starTime || !this.endTime || this.getDaysBetween(this.starTime, this.endTime) > 31) {
				this.$message({
					showClose: true,
					type: 'error',
					message: '最多可查询31天内的订单，请重新选择时间!'
				});
				return;
			}

			this.currentPage = 1;
			this.getList();
		},
		// 切换显示条数
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},

		// 翻页
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.currentPage = val;
			this.getList();
		},
		// 获取今日时间
		getNowDay() {
			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
			let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
			this.starTime = year + '-' + month + '-01' + ' ' + '00:00:00';
			this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';
		},
		// getRecentDays(days) {
		// 	let time = new Date();
		// 	let lastDay = new Date(time - 3600 * 24 * 1000);
		// 	let firstDay = new Date(time - 3600 * 24 * 1000 * days);
		// 	this.endTime = this.formatDate(lastDay);
		// 	this.starTime = this.formatDate(firstDay);
		// 	this.getList();
		// },

		getDaysBetween(dt1, dt2) {
			var startDate = Date.parse(dt1.split(' ')[0]);
			var endDate = Date.parse(dt2.split(' ')[0]);
			var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
			// alert(days);
			console.log(days + 1);
			return days + 1;
		},
		formatTen(num) {
			return num > 9 ? num + '' : '0' + num;
		},
		//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
		formatDate(date) {
			var date = new Date(date);
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			return year + '-' + this.formatTen(month) + '-' + this.formatTen(day);
		},
		wordChange(e) {
			this.currentPage = 1;
			this.getList();
		}
	}
};
</script>
<style lang="less" scoped>
.salesOverviewbox {
	background: #fff;
	padding: 10px;

	.twoFlows {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		color: #333;
		-webkit-box-orient: vertical;
	}
}
</style>
